@font-face {
  font-family: "REM-regular";
  src: local("REM"), url(../fonts/REM-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "REM-light";
  src: local("REM"), url(../fonts/REM-Light.ttf) format("truetype");
}

:root {
  --multi: repeating-linear-gradient(
    #40e0d0,
    #40e0d0 25%,
    #6495ed 25%,
    #6495ed 50%,
    #de3163 50%,
    #de3163 75%,
    #ffbf00 75%,
    #ffbf00 100%
  );
  --gold: linear-gradient(45deg, #40e0d0 25%, transparent 25%),
    linear-gradient(-45deg, #6495ed 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #de3163 75%),
    linear-gradient(-45deg, transparent 75%, #ffbf00 75%),
    repeating-linear-gradient(
      #ff9ad3,
      #ff9ad3 25%,
      #ff9ad3 25%,
      #ff9ad3 50%,
      #38c977 50%,
      #38c977 75%,
      #38c977 75%,
      #38c977 100%
    );
}

body {
  margin: 0;
  font-family: "REM-light", sans-serif;
  background: #161616;
  color: white;
  overflow: hidden;
  background-size: 300% 300%;
  background-image: linear-gradient(
    -45deg,
    #32005c 0%,
    rgb(80, 41, 117) 25%,
    rgb(55, 49, 146) 51%,
    #ab16be 100%
  );
  animation: AnimateBG 30s ease infinite;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button {
  font-family: "REM-light", sans-serif;
}

h1,
h2 {
  font-family: "REM-regular", sans-serif;
}

.app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  height: calc(100vh + 100px);
  margin-top: -100px;
}

.header {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

label {
  font-size: 20px;
}

input {
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 20px;
}

input[type="text"] {
  text-align: center;
  width: 150px;
  margin-bottom: 5px;
  background: rgb(255, 255, 255, 0.8);
}

input[type="text"]:invalid {
  outline: 2px solid red;
}

input[type="submit"] {
  width: 100%;
  color: white;
  background: rgb(0, 0, 0, 0.2);
  cursor: pointer;
}

input[type="submit"]:hover {
  width: 100%;
  color: white;
  background: rgb(0, 0, 0, 0.3);
  cursor: pointer;
}

.scoreWrapper {
  border: 10px solid rgb(39, 39, 39, 0.5);
  border-radius: 2px;
}

.score {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 2px;
}

.cubeWrapper {
  width: 310px;
  height: 310px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cube {
  background: transparent;
  padding: 5px;
  border-radius: 2px;
  width: 80px;
  height: 80px;
  overflow: hidden;
  animation-name: contentZoomIn;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  margin-top: -420px;
}

.cube > div:not(.gameOver) {
  opacity: 0;
  animation-name: contentAppear;
  animation-duration: 0.6s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}

.cubeContent {
  display: grid;
  grid-template-columns: auto auto auto;
  height: 300px;
  align-content: end;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: 70px;
  height: 70px;
  cursor: pointer;
  border-radius: 2px;
  margin: 5px;
  font-size: 20px;
  font-family: "REM-regular";
}

.item > .points {
  font-size: 24px;
}

.smallCategoryName {
  font-size: 10px;
  width: 110%;
  text-align: center;
  height: 0;
  text-transform: uppercase;
  line-height: 9px;
}

.itemContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  opacity: 0;
  animation-name: contentFadeIn;
  animation-duration: 0.6s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
}

.active {
  animation: fillSquare 0.6s cubic-bezier(0.22, 0.97, 0.51, 0.99);
  animation-fill-mode: forwards;
  cursor: default;
  background-size: 300% 300%;
}

.deactive {
  animation: shrinkSquare 0.6s cubic-bezier(0.22, 0.97, 0.51, 0.99);
  animation-fill-mode: forwards;
  cursor: default;
}

.hidden {
  display: block;
  animation: shrinkAndfadeOut 0.6s cubic-bezier(0.22, 0.97, 0.51, 0.99);
  animation-fill-mode: forwards;
  cursor: default;
}

.unhidden {
  animation: growAndFadeIn 0.6s cubic-bezier(0.22, 0.97, 0.51, 0.99);
  animation-fill-mode: forwards;
  cursor: default;
}

.gameOver {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: contentFadeIn 0.8s ease-in;
  animation-fill-mode: forwards;
  height: calc(100% - 10px);
  padding: 5px;
}

.niceButton {
  background: transparent;
  border: 2px solid rgba(235, 235, 235, 0.5);
  border-radius: 10px;
  padding: 10px;
  color: white;
  min-height: auto;
  margin: 5px 0;
  width: 50%;
}

.leaderboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
}

.row {
  display: grid;
  grid-template-columns: 50px auto 50px;
  width: 100%;
  margin: 0;
  padding: 5px;
  border-bottom: 2px solid rgb(0, 0, 0, 0.2);
}

.name,
.scorevalue {
  margin: 0;
  padding: 0;
}

.scorevalue {
  text-align: right;
}

.gameOver > p,
.leaderboard > p,
.leaderboard > h2 {
  margin: 5px;
}

.itemContent > .question {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

.itemContent > .question > p {
  margin: 0;
  font-size: 20px;
}

.item > .itemContent > p.category {
  margin: 0;
  font-size: 10px;
  text-transform: uppercase;
}

.itemContent > .buttons {
  display: grid;
  grid-template-columns: 50% auto;
  grid-auto-rows: 1fr;
  width: 100%;
  gap: 10px;
}

button {
  border: none;
  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
  min-height: 50px;
  background: rgba(255, 255, 255, 0.8);
  overflow-wrap: anywhere;
}

.itemContent > .buttons > button:disabled {
  color: black;
  pointer-events: none;
}

.itemContent > .buttons > button.correctBtn {
  background: #44cc37 !important;
}

.itemContent > .buttons > button.wrongBtn {
  background: #ff3939 !important;
}

.itemContent > .buttons > button:hover {
  background: rgba(255, 255, 255, 1);
}

@keyframes fillSquare {
  100% {
    width: 270px;
    height: 270px;
  }
}

@keyframes shrinkSquare {
  0% {
    width: 270px;
    height: 270px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 70px;
    height: 70px;
  }
}

@keyframes shrinkAndfadeOut {
  100% {
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
}

@keyframes growAndFadeIn {
  0% {
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
  100% {
    opacity: 1;
    width: 70px;
    height: 70px;
    padding: 10px;
    margin: 5px;
  }
}

@keyframes contentFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.correct {
  animation: bounce;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

.wrong {
  animation: shake;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-20px);
  }
  50% {
    border-color: #44cc37;
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-4px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes shake {
  10% {
    transform: translate(4px, 0);
  }
  20% {
    transform: translate(-4px, 0);
  }
  30% {
    transform: translate(4px, 0);
  }
  40% {
    transform: translate(-4px, 0);
  }
  50% {
    border-color: #ff3939;
    transform: translate(4px, 0);
  }
  60% {
    transform: translate(-4px, 0);
  }
  70% {
    transform: translate(4px, 0);
  }
  80% {
    transform: translate(-4px, 0);
  }
  90% {
    transform: translate(4px, 0);
  }
  0%,
  100% {
    transform: translate(0, 0);
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes contentZoomIn {
  0% {
    margin-top: -420px;
    background: transparent;
    width: 60px;
    height: 60px;
    border: 10px solid rgb(39, 39, 39, 0.5);
  }
  49% {
    width: 60px;
    height: 60px;
    border: 10px solid rgb(39, 39, 39, 0.5);
    background: transparent;
  }
  50% {
    background: rgb(39, 39, 39, 0.5);
    margin-top: 0;
    width: 80px;
    height: 80px;
    border-width: 0px;
  }
  100% {
    margin-top: 0;
    width: 300px;
    height: 300px;
    background: rgb(39, 39, 39, 0.5);
  }
}

@keyframes contentAppear {
  0% {
    opcaity: 0;
  }
  100% {
    opacity: 1;
  }
}
