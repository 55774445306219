.modal {
  background: rgb(46, 46, 46);
  border-radius: 10px;
  padding: 10px 20px;
  position: fixed;
  top: 40px;
  z-index: 1000;
  margin: 10px;
  right: -100vw;
  width: calc(100vw - 60px);
  max-width: 400px;
  font-size: 18px;
}

.modalOption {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slideIn {
  animation-name: slideIn;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.slideOut {
  animation-name: slideOut;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

@keyframes slideIn {
  0% {
    right: -min(300px, calc(100vw - 40px));
  }
  100% {
    right: 0;
  }
}

@keyframes slideOut {
  0% {
    right: 0;
  }
  100% {
    right: -min(300px, calc(100vw - 40px));
  }
}

.options {
  fill: rgb(0,0,0,0.4);
}

.options:hover {
  fill: rgb(0,0,0,0.6);
  cursor: pointer;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}